<template>
  <div id="footer">
    <div id="top-bar">
      <p>Follow Matt on Social Media</p>
      <span>
        <a class="fab fa-facebook-f" href="https://www.facebook.com/ChislehurstFitness/?fref=tag"></a>
        <a class="fab fa-instagram" href="https://www.instagram.com/chislehurst_fitness/"></a>
        <a class="fab fa-youtube" href="https://www.youtube.com/watch?v=2oiHCt5RdBM"></a>
      </span>
      <i class="fa-sharp fa-solid fa-caret-down"></i>
    </div>

    <div id="footer-container">

      <div class="flex-container">
        <div class="left">
          <img src="@/assets/logo.png" alt="Chislehurst Fitness" />

          <p>Based at Walden Recreation Ground, Chislehurst Fitness offers Group Fitness Sessions, Boxercise and Personal
            Training across Chislehurst, Bromley, Eltham, Mottingham, Petts Wood and Sidcup.</p>
        </div>

        <div class="right">
          <h3>Message me here for further information</h3>
          <form>
            <input v-model="name" type="text" placeholder="Full Name" />
            <input v-model="email" type="email" placeholder="Email" />
            <input type="checkbox" id="emailSubscribe" v-model="emailSubscribe" />
            <label for="emailSubscribe">Consent to receiving news from Chislehurst Fitness</label>
            <button type="submit" @click.prevent="submit">Send</button>
          </form>
        </div>
      </div>

      <div id="bottom">
        <p>&copy; 2023 Chislehurst Fitness |
          <i @click="this.$router.push('/terms')">Terms</i>,
          <i @click="this.$router.push('/privacy')">Privacy</i>
        </p>
        <p>Powered by <a href="https://bookedsolid.co.uk">BookedSolid</a></p>
      </div>
    </div>

  </div>
</template>

<script>
import { sendContact } from "@/app/routes";

export default {
  name: "Footer",
  data() {
    return {
      name: "",
      email: "",
      emailSubscribe: false
    };
  },
  methods: {
    async submit() {
      if (this.name == "" || this.email == "" || this.message == "") {
        alert("Please fill out all fields.");
        return;
      } else {
        this.loading = true;
        fetch(
          `https://docs.google.com/forms/d/e/1FAIpQLSewifriNmvD9IA0V9xQBl7avoHsbm33zZJupd8HEdPOx5aF_A/formResponse?usp=pp_url&entry.504883617=${this.name}&entry.992426571=${this.email}&entry.133864773=Website contact&entry.1586131694=Empty&entry.1507443460=${this.emailSubscribe ? "Yes" : "No"}`
        ).catch(() => { });
        this.loading = false;
        alert("Thank you for contacting us. We will get back to you shortly.");
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

#footer {
  width: 100%;
  background: $colourS;
  color: white;
  padding: 50px 0;
  text-align: left;
  position: relative;

  #top-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: $colourP;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 22px;
      color: white;
      font-weight: bold;
    }

    a {
      font-size: 30px;
      color: white;
      margin-left: 25px;
      opacity: 0.5;
      text-decoration: none;
    }

    i {
      position: absolute;
      bottom: -22px;
      font-size: 40px;
      color: $colourP;
    }
  }

  #footer-container {
    width: 80%;
    max-width: 1100px;
    margin: auto;
    margin-top: 100px;

    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 50px;

      .left {
        width: calc(50vw - 50px);
        max-width: 380px;
        padding-right: 80px;
        box-sizing: border-box;

        img {
          width: 100%;
          max-width: 285px;
          margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          line-height: 1.6;
          color: #FFFFFFC9;
        }
      }

      .right {
        width: calc(50vw - 50px);
        max-width: 380px;

        h3 {
          font-size: 18px;
          letter-spacing: normal;
        }

        form {
          input {
            display: block;
            width: 100%;
            padding: 11.25px;
            font-size: 16px;
            margin-bottom: 10px;
            box-sizing: border-box;

            &#emailSubscribe {
              width: auto;
              display: inline-block;
            }

            &::placeholder {
              color: #b1b0b0;
            }
          }

          label {
            font-size: 15px;
            color: #FFFFFFC9;
            margin-bottom: 20px;
          }

          button {
            display: block;
            width: 100%;
            padding: 11.25px;
            font-size: 16px;
            text-align: center;
            background: $colourP;
            color: white;
            border: none;
            font-weight: bold;
            margin-top: 20px;
          }
        }
      }
    }
  }

  #bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    width: 100%;
    border-top: solid 1px $colourP;

    p {
      color: #FFFFFF63;

      i {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    a {
      color: #2596be;
    }
  }
}

@media only screen and (max-width: 600px) {
  #footer {
    #top-bar {
      flex-direction: column;
      height: 165px;

      p {
        margin-top: 0;
      }

      a {
        font-size: 30px;
        color: white;
        margin-left: 25px;
        opacity: 0.5;
        text-decoration: none;
      }

      i {
        position: absolute;
        bottom: -22px;
        font-size: 40px;
        color: $colourP;
      }
    }

    #footer-container {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      margin-top: 150px;

      .flex-container {
        flex-direction: column;
        .left {
          width: 100%;
          max-width: unset;
          padding: 0;

          img {
            display: block;
            margin: auto;
          }

          p {
            text-align: center;
            font-size: 14px;
            line-height: 1.8;
          }
        }

        .right {
          width: 100%;
          max-width: unset;
          margin-top: 50px;

          form {
            label {
              font-size: 13px;
            }
          }
        }
      }
    }

    #bottom {
      flex-direction: column;
    }
  }
}
</style>